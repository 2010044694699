import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "margin": "16px"
  }
};
const _hoisted_2 = {
  style: {
    "margin": "16px"
  }
};
import { showToast } from 'vant';
import { onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
import Header from '../../components/storeHeader.vue';
export default {
  __name: 'index',
  setup(__props) {
    const router = useRouter();
    const data = reactive({
      nickname: '',
      motto: '',
      password: ''
    });
    const init = () => {
      if (localStorage.userInfo) {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        data.nickname = userInfo.nickname;
        data.motto = userInfo.motto;
      }
    };
    onMounted(() => {
      init();
    });
    const onSubmit = value => {
      if (localStorage.userInfo) {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (value.password) {
          userInfo.password = value.password;
        } else {
          console.log('未修改密码');
        }
        userInfo.nickname = value.nickname;
        userInfo.motto = value.motto;
        console.log(userInfo);
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
      }
    };
    const logout = () => {
      localStorage.removeItem('isLogin');
      showToast('退出成功');
      setTimeout(() => {
        router.push('/login');
      }, 1000);
    };
    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_form = _resolveComponent("van-form");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(Header, {
        title: "账号管理"
      }), _createVNode(_component_van_form, {
        onSubmit: onSubmit
      }, {
        default: _withCtx(() => [_createVNode(_component_van_cell_group, {
          inset: ""
        }, {
          default: _withCtx(() => [_createVNode(_component_van_field, {
            modelValue: data.nickname,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => data.nickname = $event),
            name: "nickname",
            label: "呢称",
            placeholder: "呢称",
            rules: [{
              required: true,
              message: '请填写呢称'
            }]
          }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
            modelValue: data.motto,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => data.motto = $event),
            name: "motto",
            label: "签名",
            placeholder: "签名",
            rules: [{
              required: true,
              message: '请填写用户名'
            }]
          }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
            modelValue: data.password,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => data.password = $event),
            type: "password",
            name: "password",
            label: "密码",
            placeholder: "密码",
            rules: [{
              message: '请填写新密码'
            }]
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_button, {
          round: "",
          block: "",
          type: "primary",
          "native-type": "submit"
        }, {
          default: _withCtx(() => [_createTextVNode(" 修改信息 ")]),
          _: 1
        })])]),
        _: 1
      }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_van_button, {
        round: "",
        block: "",
        type: "primary",
        onClick: logout
      }, {
        default: _withCtx(() => [_createTextVNode(" 退出登录 ")]),
        _: 1
      })])], 64);
    };
  }
};